/** Boot up the onboarding app! */
import { CurrentRoute, Routes } from "../types";
import Telemetry from "./telemetry";
import { isDarkMode } from "@tigris/common";

const { VITE_USE_MSW, VITE_TIGRIS_ENV } = import.meta.env;

Telemetry.init();

/** Conditionally initialize `msw` based on environment. */
export const prepareMSW = async () => {
  if (VITE_USE_MSW === "true") {
    const { worker } = await import("../mocks/browser");
    await worker.start({
      onUnhandledRequest(req) {
        if (VITE_TIGRIS_ENV === "local") {
          // eslint-disable-next-line no-console
          console.error(
            "Found an unhandled %s request to %s",
            req.method,
            req.url,
          );
        }
      },
    });
  }
};

/**
 * Sets the `.dark-mode` class on the root element.
 * This will be used as a signal to our CSS layer to render dark mode styles.
 */
const setDarkMode = () => document.documentElement.classList.add("dark-mode");

/**
 * Execute DOM interactions and setup prior to initializing the React application.
 *
 * All side-effects of inferred configuration should be performed here.
 */
export const prepare = (): {
  /**
   * The HTML element to render the React application into.
   */
  rootElement: HTMLElement;

  /** If available, the initial route to begin the app. */
  initialRoute: CurrentRoute;
} => {
  const rootElement = document.getElementById("root") as HTMLElement;

  // We use `.dark-mode` classname to render dark mode styles.
  const userPrefersDarkMode = isDarkMode();
  if (userPrefersDarkMode) setDarkMode();

  const [initialRoute] = location.pathname.split("/").filter((x) => x);

  if (initialRoute) {
    const matched = Object.entries(Routes).find(
      ([_, route]) => route === `/${initialRoute}`,
    );

    if (matched) {
      return { rootElement, initialRoute: { pathname: matched[1] } };
    }
  }

  return { rootElement, initialRoute: { pathname: Routes.Root } };
};
