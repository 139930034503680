import "../main.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { EnvironmentBanner, MesoKitContext } from "@tigris/mesokit";
import { Scope } from "@sentry/react";
import { Amplitude, Sentry } from "./utils/telemetry";
import { toast } from "sonner";
import { prepare, prepareMSW } from "./utils/boot";
import { RouterContextProvider } from "./contexts/RouterContextProvider";
import { routes } from "./routes";
import { OnboardingAppForTransfer } from "./components/OnboardingAppForTransfer";
import { ErrorCard } from "./components/ErrorCard";
import { ToggleDevMode } from "./dev/ToggleDevMode";

const { rootElement, initialRoute } = prepare();

(async () => {
  await prepareMSW();

  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={<ErrorCard />}
        beforeCapture={(scope: Scope) => scope.setLevel("warning")}
      >
        <RouterContextProvider routes={routes} initialRoute={initialRoute}>
          <MesoKitContext.Provider
            value={{ sentry: Sentry, amplitude: Amplitude, toast }}
          >
            <OnboardingAppForTransfer />
          </MesoKitContext.Provider>
        </RouterContextProvider>
      </Sentry.ErrorBoundary>
      <ToggleDevMode />
      <div className="fixed left-0 top-4 flex w-full justify-center">
        <EnvironmentBanner />
      </div>
    </React.StrictMode>,
  );
})();
