import { useState } from "react";

export const ToggleDevMode = () => {
  const [isDevMode, setIsDevMode] = useState(() => {
    return new URLSearchParams(location.search).get("devMode") === "true";
  });

  // This statement prevents this entire module from being bundled in our production builds
  if (!(import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV)) {
    return;
  }

  return (
    <div className="fixed bottom-2 left-2">
      <button
        className="rounded-xl bg-yellow-400 p-2 text-xs font-bold text-yellow-800 dark:bg-yellow-500"
        onClick={() => {
          if (isDevMode) {
            setIsDevMode(false);
            location.href = location.origin;
          } else {
            setIsDevMode(true);
            location.search = "?devMode=true";
          }
        }}
      >
        → {isDevMode ? "Use Integration mode" : "Use dev mode"}
      </button>
    </div>
  );
};
